import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { getAllBrands } from "../../services/brandApi";

const AllBrandList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAllBrands = () => {
    setLoading(true);
    getAllBrands()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          console.log("Res", Res);
          setBrandList(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state with the input value
  };
  // Function to filter orderData based on search query
  
    const filteredData = brandList
      .filter((item) => {
      const query = searchQuery.toLowerCase();
      return (
        item.name.toLowerCase().includes(query) ||
        item.mobileNo.toLowerCase().includes(query) ||
        item.email.toLowerCase().includes(query) ||
        item.brandCode.toLowerCase().includes(query)
      );
    })
      .sort((a, b) => {
        // Assuming 'name' is a property of the garment object
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-3 ml-3">
          <Col>
            <InputText
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Brand..."
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/add-brand"
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add Brand
            </Link>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                  Brand List is Loading
                </h3>
              </div>
            ) : filteredData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>Brand List </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Brand Name</th>
                            <th>Icon</th>
                            <th>Mobile No</th>
                            <th>Email</th>
                            <th>Brand Code</th>
                            <th>View Details</th>
                            <th>Edit</th>
                            <th>Config User</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>
                                <img
                                  src={
                                    item.logo
                                      ? item.logo
                                      : "/Img/entrepreneur.png"
                                  }
                                  alt="logo"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </td>
                              <td>{item.mobileNo}</td>
                              <td>{item.email}</td>
                              <td>{item.brandCode}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/view-brand-details", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-file"
                                    style={{ color: "green", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/add-brand", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-pencil"
                                    style={{ color: "green", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/config-user", {
                                      state: item.id,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-cog"
                                    style={{ color: "blue", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No Brand Found</h3>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllBrandList;
